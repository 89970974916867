/* eslint-disable no-nested-ternary */
import Thumbor from '@common_slick/Banner/Thumbor';
import ProductVideo from '@common_slick/Banner/productVideo';
import useStyles from '@common_slick/Banner/style';
import classNames from 'classnames';
import React from 'react';

/**
 slug page need props 'href' & 'as' to prevent browser reloading
 *isSlug == true => <link href="/[...slug]" as={link} />
 *isSlug == false => <link href={link} />
*/

const ImageSlide = ({
    width,
    height,
    imageUrl = '',
    link = '#',
    noLink = false,
    // isSlug = true,
    mobileImageUrl = '',
    contentWidth,
    customClass = '',
    video,
    videoUrl,
    storeConfig,
    alt = '',
    preload = false,
    sliderType,
    customHtml,
    customCss,
}) => {
    const styles = useStyles();
    const href = noLink ? null : (link && link.includes('http://')) || link.includes('https://') ? link : link[0] === '/' ? link : `/${link}`;

    if (video) {
        if (imageUrl && video) {
            return <ProductVideo video={video} />;
        }
        if (!imageUrl && video) {
            return <ProductVideo video={video} />;
        }
    }
    if (videoUrl) {
        if (videoUrl.video_url) {
            return <ProductVideo videoUrl={videoUrl} />;
        }
    }
    return (
        <div style={{ position: 'relative' }}>
            <a href={href}>
                <Thumbor
                    src={imageUrl}
                    srcMobile={mobileImageUrl}
                    width={width || storeConfig?.pwa?.home_slider_desktop_width}
                    height={height || storeConfig?.pwa?.home_slider_desktop_height}
                    widthMobile={width || storeConfig?.pwa?.home_slider_mobile_width}
                    heightMobile={height || storeConfig?.pwa?.home_slider_mobile_height}
                    alt={alt}
                    quality={100}
                    className={contentWidth === 'auto' ? classNames(styles.imageSliderAuto, styles.imageSlider) : styles.imageSlider}
                    contentWidth={contentWidth}
                    customClass={customClass}
                    storeConfig={storeConfig}
                    preload={preload}
                    sliderType={sliderType}
                />
            </a>
            { customHtml && (
                <>
                    <div className="custom-html-section">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: customHtml }} />
                    </div>
                    <style>{customCss}</style>
                    <style>
                        {`
                            .btn-banner-container {
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                gap: 5px;
                                right: 0;
                                bottom: 6%
                            }
                            .btn-banner {
                                color: #fff !important;
                                background: #00174A;
                                border-radius: 5px;
                                text-align: center;
                                font-weight: bold;
                                padding: 10px 0;
                                font-size: 9px;
                                width: 69px;
                            }
                            .btn-banner:hover {
                                color: #fff !important;
                                background: #001e62;
                            }
                            @media only screen and (min-width: 1024px){
                                .btn-banner-container {
                                    gap: 10px;
                                    right: 21%;
                                    bottom: 10%;
                                    flex-direction: row;
                                }
                                .btn-banner {
                                    border-radius: 8px;
                                    padding: 20px 0;
                                    font-size: 16px;
                                    min-width: 150px;
                                    padding: 15px 0;
                                    font-size: 16px;
                                }
                            }
                            @media only screen and (min-width: 1200px){
                                .btn-banner {
                                    font-size: 18px;
                                    min-width: 175px;
                                    padding: 20px 0;
                                }
                            }
                            @media only screen and (min-width: 750px) and (max-width: 768px){
                                .btn-banner {
                                    width: 125px;
                                    font-size: 14px;
                                    padding: 15px 5px;
                                }
                            }
                            @media only screen and (min-width: 519px) and (max-width: 749px){
                                .btn-banner {
                                    width: 80px;
                                    font-size: 11px;
                                    padding: 10px 5px;
                                }
                            }
                            @media only screen and (max-width: 375px){
                                .btn-banner {
                                    width: 59px;
                                    font-size: 7px;
                                }
                            }
                            @media only screen and (max-width: 320px){
                                .btn-banner {
                                    width: 50px;
                                    font-size: 7px;
                                }
                            }
                        `}
                    </style>
                </>
            )}
        </div>
    );
};

export default ImageSlide;
