import dayjs from 'dayjs';

const TIMEZONE_OFFSET = 7;

export const formatDateTimezone = (date = new Date(), format = 'D MMMM YYYY, HH:mm:ss') => {
    const dayjsDate = dayjs(date);
    return dayjsDate.hour(dayjsDate.hour() + TIMEZONE_OFFSET).format(format);
};

const formatDate = (date = new Date(), format = 'D MMMM YYYY, HH:mm:ss') => dayjs(date).format(format);

export const formatDateWib = (date = new Date(), format = 'D MMMM YYYY, HH.mm WIB') => dayjs(date).format(format);

export const formatDateTimestamp = (timestamp, format = 'DD/MM/YYYY') => {
    const date = dayjs.unix(timestamp);
    return date.format(format);
};

export default formatDate;
