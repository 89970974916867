/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@common_typography';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import RedeemIcon from '@material-ui/icons/Redeem';
import ProductItem from '@plugin_productitem';
import useStyles from '@core_modules/checkout/pages/default/components/PromoModalItem/style';

const Caraousel = dynamic(() => import('@common_slick/Caraousel'), { ssr: false });

const PromoModalItemView = (props) => {
    const {
        items, handleAddToCart, handleClickOpen, handleClose, open,
        availableMaxQty, customQty, cartItems,
    } = props;

    const styles = useStyles();
    const [isFreeItemInCart, setIsFreeItemInCart] = useState(false);

    // Function to check if a free item is in the cart
    const checkFreeItemInCart = () => {
        if (!Array.isArray(cartItems) || !Array.isArray(items)) return false;

        return items.some((item) =>
            cartItems.some((cartItem) => cartItem.product.sku === item.sku));
    };

    useEffect(() => {
        setIsFreeItemInCart(checkFreeItemInCart());
    }, [cartItems, items]); // Update whenever cartItems or items change

    useEffect(() => {
        if (isFreeItemInCart) {
            const style = document.createElement('style');
            style.innerHTML = `
                #hadiahGratisButton {
                    color: white !important;
                    pointer-events: none !important;
                }
            `;
            document.head.appendChild(style);

            return () => {
                document.head.removeChild(style);
            };
        }

        return undefined;
    }, [isFreeItemInCart]); // Update whenever isFreeItemInCart changes

    const hideArrows = items.length < 5;

    if (isFreeItemInCart) {
        return <></>;
    }
    return (
        <>
            {/* Free item container with conditional styles */}
            <div
                className={classNames(
                    styles.freeItemContainer,
                    { [styles.disabledContainer]: isFreeItemInCart }, // Apply gray background if free item is in cart
                )}
            >
                <RedeemIcon />
                <Button
                    variant="text"
                    onClick={handleClickOpen}
                    className={classNames(styles.customButton, { [styles.disabledButton]: isFreeItemInCart })} // Disable button and change text color if free item is in cart
                    disabled={isFreeItemInCart} // Disable button if free item is in cart
                >
                    <Typography className={styles.typographyHover} id="hadiahGratisButton">
                        Pilih
                        {' '}
                        <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>HADIAH GRATIS</span>
                        {' '}
                        Anda
                    </Typography>
                </Button>
            </div>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
                <MuiDialogTitle disableTypography className={styles.root} id="customized-dialog-title">
                    <Typography variant="h1">Hadiah Gratis</Typography>
                    {handleClose ? (
                        <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <div className={classNames(styles.carouselContainer, 'col-xs-12 col-lg-12')}>
                    <Caraousel
                        data={items}
                        Item={ProductItem}
                        enableAddToCart
                        enableOption
                        handleAddToCart={handleAddToCart}
                        enableWishlist={false}
                        enablePrice={false}
                        enableRating={false}
                        enableQuickView={false}
                        showQty={false}
                        customQty={customQty}
                        maxQty={availableMaxQty}
                        slideLg={4}
                        showArrow={!hideArrows}
                        itemClassName={styles.itemContainer}
                    />
                </div>
            </Dialog>
        </>
    );
};

export default PromoModalItemView;
