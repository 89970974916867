import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreatePadding, FlexColumn } from '@theme_mixins';

export default makeStyles((theme) => ({
    container: {
        ...FlexColumn,
        ...CreatePadding(0, 20, 10, 25),
        width: '100%',
        '& > div:first-child > div:first-child': {
            display: 'flex',
        },
    },
    containerAggrement: {
        ...FlexColumn,
        ...CreatePadding(0, 20, 10, 25),
        width: '100%',
        '& > div:first-child > div:first-child': {
            display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
            ...CreatePadding(0, 0, 10, 0),
            marginBottom: 40,
        },
    },
    checkboxText: {
        color: 'black !important',
        backgroundColor: 'transparent',
        textTransform: 'none !important',
        textDecoration: 'underline',
        fontSize: '12px',
        textAlign: 'left',
        paddingLeft: 0,
    },
}));
