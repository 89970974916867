import makeStyles from '@material-ui/core/styles/makeStyles';
import { PRIMARY } from '@theme_color';
import { CreateBorder, FlexRow } from '@theme_mixins';

export default makeStyles((theme) => ({
    root: {
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
        ...FlexRow,
    },
    rootRmBorder: {
        ...FlexRow,
        border: 'none',
    },
    labelContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        gap: '20px',
    },
    labelBox: {
        flex: 1,
    },
    labelContainerActive: {
        fontWeight: 'bold',
    },
    originalPrice: {
        textDecoration: 'line-through',
        marginLeft: 'auto',
    },
    promo: {
        marginLeft: 'auto',
        fontWeight: 'bold',
    },
    notPromo: {
        marginLeft: 'auto',
        fontWeight: 'normal',
    },
    freeShipping: {
        marginLeft: 'auto',
        fontWeight: 'bold',
    },
    imgContainer: {
        width: 'fit-content !important',
        lineHeight: 0,
        [theme.breakpoints.down('sm')]: {
            width: '65px !important',
        },
    },
    infoUob: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            right: '5px',
        },
    },
    imgList: {
        maxWidth: 80,
    },
    radioItemContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            gap: '5px',
        },
    },
    radioWrapper: {
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
        },
    },
}));
