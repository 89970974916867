import { makeStyles } from '@material-ui/core/styles';
import { GRAY_PRIMARY, WHITE } from '@theme_color';
import { FlexRow } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex', // This will enable flexbox for centering
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: GRAY_PRIMARY,
    },
    padding: {
        // padding: theme.spacing(2),
    },

    carouselContainer: {
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 70,
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .slick-slide': {
            '& > div': {
                marginLeft: '5px',
                marginRight: '5px',
            },
        },
        '& .productTitle': {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            textOverflow: 'ellipsis',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            height: '2.5rem',
        },
    },
    carouselTitle: {
        marginBottom: '20px',
    },
    itemContainer: {
        paddingBottom: '10px', // Add bottom padding to item container
    },
    freeItemContainer: {
        lineHeight: '12px',
        [theme.breakpoints.up('sm')]: {
            padding: 10,
        },
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'center',
        background: '#BB421B',
        position: 'sticky',
        top: 50,
        color: WHITE,
        '& span': {
            color: WHITE,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 'unset',
            width: '100%',
            left: 'unset',
            padding: '5px 0',
        },
    },
    freeItemContainerMobileFixed: {
        [theme.breakpoints.up('sm')]: {
            padding: 10,
        },
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'center',
        background: '#BB421B',
        position: 'sticky',
        top: 37,
        color: WHITE,
        '& span': {
            color: WHITE,
        },
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            top: 0,
            width: '100%',
            left: 0,
        },
    },
    typographyHover: {
        cursor: 'pointer',
        transition: 'text-decoration 0.3s ease', // Smooth transition for underline
        '&:hover': {
            textDecoration: 'underline', // Underline on hover
            textDecorationColor: WHITE,
            cursor: 'pointer',
        },
    },
    customButton: {
        padding: 0,
        '& #hadiahGratisButton': {
            lineHeight: '1',
            marginBottom: 0,
            marginTop: 0,
        },
    },
    disabledContainer: {
        backgroundColor: GRAY_PRIMARY, // Light gray background
        pointerEvents: 'none', // Disable interactions with this container
    },
    disabledButton: {
        color: WHITE, // White text when disabled
        pointerEvents: 'none', // Disable interaction
    },
    freeItemContainerInfo: {
        background: '#b22222',
        color: '#fff',
        padding: '10px',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
    },
}));

export default useStyles;
